import React, { useEffect, useState } from "react"
import AttendanceAnalytics from "../components/AttendanceAnalytics/AttendanceAnalytics"
import { AttendanceMonthlyStats } from "../components/AttendanceMonthlyStats"
import SplitLayout from "../components/layouts/SplitLayout/SplitLayout"
import RollCallMode from "../components/RollCallMode"
import TabView, { Tab } from "../components/TabView/TabView"
import UpdateAttendance from "../components/UpdateAttendance/UpdateAttendance"

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>
}
const Attendance: React.FC<Props> = ({ setTitle }: Props) => {
  const [data, setData] = useState(STUDENT_DATA)
  const tabs: Array<Tab> = [
    {
      title: "Roll Call",
      body: <RollCallMode data={data} setData={setData} />
    },
    {
      title: "Update Attendance",
      body: <UpdateAttendance data={data} setData={setData} />
    }
  ]

  // Set and reset App bar title
  useEffect(() => {
    setTitle("Attendance")
    return () => setTitle("")
  })
  return (
    <>
      <SplitLayout
        left={
          <>
            <AttendanceMonthlyStats />

            <TabView tabs={tabs} />
          </>
        }
        right={<AttendanceAnalytics attendanceStats={[80, 3, 2, 1]} />}
      />
    </>
  )
}

export default Attendance

export interface StudentData {
  name: string
  status: boolean
}

const STUDENT_DATA: Array<StudentData> = [
  { name: "Karthikeya P Malimath", status: false },
  { name: "Dhruv Patel", status: false },
  { name: "Shashank Athreya", status: false },
  { name: "Gululaj H L", status: false },
  { name: "Ben Dower", status: false },
  { name: "Gerge Bush", status: false }
]
