import React from "react"

interface Props {
  title: string
  children: React.ReactNode
}

const CardwithHeading: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
        </div>

        <ul className="divide-y divide-gray-200">{children}</ul>
      </div>
    </>
  )
}

export default CardwithHeading
