import React from "react"
import CardwithHeading from "../layouts/CardWithHeading/CardwithHeading"
import StackedListItem from "../StackedListItem/StackedListItem"

const UpcomingEvents: React.FC = () => {
  return (
    <CardwithHeading title={"Upcoming Events"}>
      <StackedListItem />
    </CardwithHeading>
  )
}

export default UpcomingEvents
