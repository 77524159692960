import React from "react"
import { useState } from "react"
import Modal from "../layouts/Modal/Modal"

const CreateAnnouncement: React.FC = () => {
  const [open, setOpen] = useState(true)
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <Modal open={open} setOpen={setOpen} />
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Create an Announcement
        </h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>
              Make an announcement to relavent people. Click the button to open
              announcement creation wizard
            </p>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                setOpen(true)
              }}
            >
              New Announcement
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAnnouncement
