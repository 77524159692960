import React from "react"
import { NavLink } from "react-router-dom"

export interface Tile {
  name: string
  route: string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}
interface Props {
  item: Tile
}

const SideBarTile: React.FC<Props> = ({ item }: Props) => {
  return (
    <NavLink to={item.route} activeClassName="selected" className="tile">
      <item.icon aria-hidden="true" data-testid="sidebar-tile-icon" />
      <span>{item.name}</span>
    </NavLink>
  )
}

export default SideBarTile
