interface Props {
  left: React.ReactNode
  right: React.ReactNode
}

const SplitLayout: React.FC<Props> = ({ left, right }: Props) => {
  return (
    <div className="space-y-5 md:grid md:grid-cols-4 md:space-x-5 md:space-y-0">
      <div className="col-span-3">{left}</div>

      <div>
        <div>{right}</div>
      </div>
    </div>
  )
}

export default SplitLayout
