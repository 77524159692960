/* This example requires Tailwind CSS v2.0+ */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React from "react"
import { useTable, Column } from "react-table"

export type StudentData = {
  col1: string
  col2: string
  col3: number
  col4: number
  col5: number
  col6: number
  col7: number
  col8: number
}

const Timetable: React.FC = () => {
  const data = React.useMemo(
    () => [
      {
        col1: "Monday",
        col2: "6-b",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      },
      {
        col1: "Tuesday",
        col2: "7-A",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      },
      {
        col1: "Wedneday",
        col2: "",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      },
      {
        col1: "Thursday",
        col2: "",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      },
      {
        col1: "Friday",
        col2: "8-A",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      },
      {
        col1: "Saturday",
        col2: "",
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        col7: 3,
        col8: 3
      }
    ],
    []
  )

  const cellVal: React.FC = ({ row }: any) => (
    // <div className="bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
    //   {row.values.col2}
    // </div>
    <div className="bg-red-100 hover:bg-red-200 rounded-md	 w-14 h-14 ">
      <div className="flex  justify-center items-center">
        <div className="font-sans text-base	">{row.values.col2}</div>
      </div>
    </div>
  )
  const columns = React.useMemo(
    () =>
      [
        {
          accessor: "col1" // accessor is the "key" in the data
        },
        {
          Header: "8-9",
          accessor: "col2",
          Cell: cellVal
        },
        {
          Header: "9-10",
          accessor: "col3",
          Cell: cellVal
        },
        {
          Header: "10-11",
          accessor: "col4",
          Cell: cellVal
        },
        {
          Header: "11-12",
          accessor: "col5",
          Cell: cellVal
        },
        {
          Header: "Lunch Break (12-1)",
          accessor: "col6",
          Cell: cellVal
        },
        {
          Header: "1-2",
          accessor: "col7",
          Cell: cellVal
        },
        {
          Header: "2-3",
          accessor: "col8",
          Cell: cellVal
        }
      ] as Column<StudentData>[],
    []
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<StudentData>({ columns, data })
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Timetable
