import React from "react"
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import Assessments from "./pages/Assessments"
import Attendance from "./pages/Attendance"
import Dashboard from "./pages/Dashboard"
import Diary from "./pages/Diary"
import Kb from "./pages/Kb"
import Login from "./pages/Login"
import NotFound from "./pages/NotFound"
import Reports from "./pages/Reports"
import SignUp from "./pages/SignUp"

import { useState } from "react"
import AppLayout from "./components/layouts/AppLayout"

const App: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [pageTitle, setPageTitle] = useState("")
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route path="/teacher">
          <AppLayout
            setMobileMenuOpen={setMobileMenuOpen}
            mobileMenuOpen={mobileMenuOpen}
            title={pageTitle}
          >
            <Switch>
              <Route path="/teacher/dashboard">
                <Dashboard setTitle={setPageTitle} />
              </Route>
              <Route path="/teacher/assessments">
                <Assessments setTitle={setPageTitle} />
              </Route>
              <Route path="/teacher/kb">
                <Kb setTitle={setPageTitle} />
              </Route>
              <Route path="/teacher/reports">
                <Reports setTitle={setPageTitle} />
              </Route>
              <Route path="/teacher/diary">
                <Diary setTitle={setPageTitle} />
              </Route>
              <Route path="/teacher/attendance">
                <Attendance setTitle={setPageTitle} />
              </Route>
            </Switch>
          </AppLayout>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
