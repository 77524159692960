import React, { useEffect } from "react"
import Card from "../components/Card"

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const Diary: React.FC<Props> = ({ setTitle }: Props) => {
  // Set and reset App bar title
  useEffect(() => {
    setTitle("Diary")
    return () => setTitle("")
  })
  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        {/* Primary column */}
        <section
          aria-labelledby="primary-heading"
          className="min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last"
        >
          <h1 id="primary-heading" className="sr-only">
            Photos
          </h1>
          <Card />

          {/* Your content */}
        </section>
        {/* Content area */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Main content */}
        </div>
      </main>
    </div>
  )
}

export default Diary
