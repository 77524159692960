import React from "react"
import { Doughnut } from "react-chartjs-2"
import AbsenteesList from "../AbsenteesList"

interface Props {
  attendanceStats: Array<number>
}

const options = {
  cutoutPercentage: 10
}

const AttendanceAnalytics: React.FC<Props> = ({ attendanceStats }: Props) => {
  const data = {
    labels: [
      "Present Studentes",
      "Absentees",
      "Continuous Absentees",
      "Permission Taken"
    ],
    datasets: [
      {
        data: attendanceStats,
        backgroundColor: ["#10B981", "#EF4444", "#B91C1C", "#FBBF24"],
        hoverBackgroundColor: ["#10B981", "#EF4444", "#B91C1C", "#FBBF24"]
      }
    ]
  }

  return (
    <div className="m-5">
      <div className="bg-white object-contain shadow-md rounded-2xl my-3">
        <div>
          <p className="text-3xl p-5 text-center">Attendance Report</p>
        </div>
        <div className=" container-md p-5">
          <Doughnut data={data} options={options} type="doughnut" />
        </div>
      </div>

      {/* Attendance chart end */}
      {/* Attendance absent table */}

      <div className="bg-white object-contain shadow-md rounded-2xl my-3">
        <div>
          <p className="text-3xl p-5 text-center">Absentees List</p>
        </div>
        <div className=" container-md p-5">
          <AbsenteesList />
        </div>
      </div>
    </div>
  )
}

export default AttendanceAnalytics
