import {
  HomeIcon,
  UserGroupIcon,
  ClipboardCheckIcon,
  BookmarkAltIcon,
  ChartPieIcon,
  BookOpenIcon
} from "@heroicons/react/outline"
import { Tile } from "./components/SideBarTile/SideBarTile"

export const sidebarNavigation: Array<Tile> = [
  {
    name: "Home",
    route: "/teacher/dashboard",
    icon: HomeIcon
  },
  {
    name: "Attendance",
    route: "/teacher/attendance",
    icon: UserGroupIcon
  },
  {
    name: "Assessments",
    route: "/teacher/assessments",
    icon: ClipboardCheckIcon
  },
  {
    name: "School Diary",
    route: "/teacher/diary",
    icon: BookmarkAltIcon
  },
  {
    name: "Reports",
    route: "/teacher/reports",
    icon: ChartPieIcon
  },
  {
    name: "Knowledge Bank",
    route: "/teacher/kb",
    icon: BookOpenIcon
  }
]

export const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Sign out", href: "#" }
]
