import React from "react"
import CreateAnnouncement from "../CreateAnnouncement/CreateAnnouncement"
import Timetable from "../Timetable/Timetable"

const DashboardLeftPane = () => {
  return (
    <div className="flex flex-col space-y-5">
      <CreateAnnouncement />
      <Timetable />
    </div>
  )
}

export default DashboardLeftPane
