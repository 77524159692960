import React, { useEffect } from "react"
import { useState } from "react"
import CreateAnnouncement from "../components/CreateAnnouncement/CreateAnnouncement"
import DashboardLeftPane from "../components/DashboardLeftPane/DashboardLeftPane"
import SplitLayout from "../components/layouts/SplitLayout/SplitLayout"
import Profile from "../components/Profile"
import Timetable from "../components/Timetable/Timetable"
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents"

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const Dashboard: React.FC<Props> = ({ setTitle }: Props) => {
  // Set and reset App bar title

  useEffect(() => {
    setTitle("Dashboard")
    return () => setTitle("")
  })
  return <SplitLayout left={<DashboardLeftPane />} right={<UpcomingEvents />} />
}

export default Dashboard
