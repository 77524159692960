import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Tile } from "../SideBarTile/SideBarTile"

interface Props {
  item: Tile
}
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ")
}

const MobileSideBarTile: React.FC<Props> = ({ item }: Props) => {
  const location = useLocation()
  const isActiveTile = location.pathname == item.route
  return (
    <Link to={item.route}>
      <a
        key={item.name}
        className={classNames(
          isActiveTile
            ? "bg-primary-800 text-white"
            : "text-primary-100 hover:bg-primary-800 hover:text-white",
          "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
        )}
        aria-current={isActiveTile ? "page" : undefined}
      >
        <item.icon
          className={classNames(
            isActiveTile
              ? "text-white"
              : "text-primary-300 group-hover:text-white",
            "mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
        <span>{item.name}</span>
      </a>
    </Link>
  )
}

export default MobileSideBarTile
