import React from "react"
import Rocket from "../assets/images/rocket.png"
import Logo from "../assets/images/logo.png"
import { RouteChildrenProps } from "react-router"

const SignUp: React.FC<RouteChildrenProps> = ({
  history
}: RouteChildrenProps) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    history.replace("/dashboard")
  }

  return (
    <div>
      <div className="mt-4 ml-10">
        <img className="max-h-11" src={Logo} alt="logo" />
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-24">
        <div
          style={{
            background:
              "linear-gradient(213.7deg, #FFFFFF 54.62%, #FDA4AF 110.1%)"
          }}
          className="hidden lg:block"
        >
          <img src={Rocket} alt="Rocket" />
        </div>

        <div className="min-h-screen flex flex-col justify-center px-2 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-left text-6xl font-extrabold text-gray-900">
              Sign Up
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <p className="text-sm text-[#6B7280]">
                  By signing up, you agree to our{" "}
                  <span className="text-black font-semibold">
                    Terms, Data Policy
                  </span>{" "}
                  and{" "}
                  <span className="text-black font-semibold">
                    Cookies Policy
                  </span>
                  .
                </p>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
