import React from "react"
import LogoWhite from "../assets/images/logo-white.png"
import SideBarTile, { Tile } from "../components/SideBarTile/SideBarTile"

interface Props {
  items: Array<Tile>
}

const SideBar: React.FC<Props> = ({ items }: Props) => {
  return (
    <div className="hidden w-28 bg-primary-500 overflow-y-auto md:block">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-shrink-0 flex items-center">
          <img className="h-12 w-auto" src={LogoWhite} alt="Workflow" />
        </div>
        <div className="sidebar flex-1 mt-6 w-full px-2 space-y-1">
          {items.map((item, idx) => (
            <SideBarTile item={item} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default SideBar
