import React from "react"
import { MenuAlt2Icon } from "@heroicons/react/outline"
import ProfileDropdown from "./ProfileDropdown"

interface Props {
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
}
const TopBar: React.FC<Props> = ({ setMobileMenuOpen, title }: Props) => {
  return (
    <header className="w-full">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex items-center">
            <h1 className="text-xl lg:text-3xl font-bold">{title}</h1>
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  )
}

export default TopBar
