import { useState } from "react"
import { StudentData } from "../pages/Attendance"
import AttendanceCard from "./AttendanceCard"

export interface AttendanceModeProps {
  data: Array<StudentData>
  setData: React.Dispatch<React.SetStateAction<Array<StudentData>>>
}
const RollCallMode: React.FC<AttendanceModeProps> = ({
  data,
  setData
}: AttendanceModeProps) => {
  const [current, setCurrent] = useState(0)

  const setter = (idx: number) => {
    return (value: StudentData["status"]) => {
      const tempData = [...data]
      tempData[idx].status = value
      setData(tempData)
    }
  }
  const cards = data.map((student, idx) => (
    <AttendanceCard
      key={idx}
      active={current === idx}
      data={student}
      setter={setter(idx)}
      clickNext={() => setCurrent((current + 1) % data.length)}
    />
  ))
  return <div>{<Carousel current={current} items={cards} />}</div>
}
export default RollCallMode

interface CarouselProps {
  current: number
  items: Array<React.ReactNode>
}

const Carousel: React.FC<CarouselProps> = ({
  current,
  items
}: CarouselProps) => {
  return (
    <div className="flex justify-center">
      <div className="w-1/3 filter brightness-75 ">
        {current - 1 >= 0 ? items[current - 1] : "DEFAULT CARD PLACEHOLDER"}
      </div>
      <div className="w-1/3">{items[current % items.length]}</div>
      <div className="w-1/3 filter brightness-75 ">
        {current + 1 < items.length
          ? items[current + 1]
          : "DEFAULT CARD PLACEHOLDER"}
      </div>
    </div>
  )
}
