import React from "react"
import { Link } from "react-router-dom"

const NotFound: React.FC = () => {
  return (
    <div className="grid place-items-center">
      <h1 className="text-primary-600 text-[20rem]">404</h1>
      <p className="text-lg">
        Sorry, we could not find the page you are looking for, click{" "}
        <Link to="/" className="text-primary-600">
          here
        </Link>{" "}
        to return to home page
      </p>
    </div>
  )
}

export default NotFound
