import React from "react"
import { StudentData } from "../pages/Attendance"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

interface StudentCardProps {
  data: StudentData
  active: boolean
  setter: (value: StudentData["status"]) => void
  clickNext: () => void
}

const AttendanceCard: React.FC<StudentCardProps> = ({
  data,
  active,
  setter,
  clickNext
}: StudentCardProps) => {
  return (
    //  {/* Card 1 */}
    // <div className="container-md bg-white shadow-md  rounded-3xl m-6 p-4">
    //   <div className="flex flex-col items-center m-6">
    //     <div className=" h-full w-full lg:h-48 lg:w-48   lg:mb-0 mb-3">
    //       <img
    //         src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80"
    //         alt="Just a flower"
    //         className=" w-full  object-scale-down lg:object-cover  lg:h-48 rounded-2xl"
    //       />
    //     </div>
    //     <div className="flex-auto justify-evenly text-center p-4">
    //       <div className="flex flex-wrap">
    //         <h2 className="flex-auto text-lg font-medium">{data.name}</h2>
    //       </div>
    //       <div className="text-sm text-gray-600">
    //         <div className="text-center">
    //           <p>Grade 8 Section B</p>
    //           <p>{data.status ? "Present" : "Absent"}</p>
    //         </div>
    //       </div>
    //       <div className="flex space-x-3 text-sm font-medium m-6">
    //         <div className="flex-auto flex space-x-3">
    //           <button
    //             className="mb-2 md:mb-0 bg-green-600 px-5 py-2 shadow-sm tracking-wider border text-white rounded-full hover:bg-green-400 inline-flex items-center space-x-2 "
    //             onClick={() => {
    //               clickNext()
    //               setter(true)
    //             }}
    //             disabled={!active}
    //           >
    //             <span>Present</span>
    //           </button>
    //         </div>
    //         <button
    //           className="mb-2 md:mb-0 bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800"
    //           type="button"
    //           aria-label="like"
    //           onClick={() => {
    //             clickNext()
    //             setter(false)
    //           }}
    //           disabled={!active}
    //         >
    //           Absent
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 m-6 ">
      <div className="flex-1 flex flex-col p-8">
        <img
          className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full"
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
          alt=""
        />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">{data.name}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-gray-500 text-sm">Class 8-B</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              {data.status ? "Present" : "Absent"}
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <button
              onClick={() => {
                clickNext()
                setter(true)
              }}
              disabled={!active}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Present</span>
            </button>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <button
              type="button"
              aria-label="like"
              onClick={() => {
                clickNext()
                setter(false)
              }}
              disabled={!active}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
            >
              <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Absent</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttendanceCard
