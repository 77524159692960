import React from "react"

interface Props {
  children: React.ReactNode
}

const AppPageParent: React.FC<Props> = ({ children }: Props) => {
  return <div className="overflow-auto p-6">{children}</div>
}
export default AppPageParent
