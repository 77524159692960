function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ")
}

interface TabBarProps {
  tabs: Array<string>
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  activeTab,
  setActiveTab
}: TabBarProps) => {
  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={event => setActiveTab(Number(event.target.value))}
          >
            {tabs.map((tab, idx) => (
              // Using selected is not encouraged in React, but without forcing this, state changed elsewhere wont reflect in this select component
              <option key={tab} value={idx} selected={idx === activeTab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab, idx) => (
              <button
                key={tab}
                onClick={() => setActiveTab(idx)}
                className={classNames(
                  idx === activeTab
                    ? "bg-green-100 text-gray-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </>
  )
}

export default TabBar
