import React, { useEffect } from "react"
import Logo from "../assets/images/logo-rose.png"

import { SearchIcon, VideoCameraIcon } from "@heroicons/react/solid"

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const Kb: React.FC<Props> = ({ setTitle }: Props) => {
  // Set and reset App bar title
  useEffect(() => {
    setTitle("Knowledge Bank")
    return () => setTitle("")
  })
  return (
    <div className="flex flex-col justify-center">
      <img className="h-1/3 w-1/3 self-center p-10" src={Logo} alt="logo" />
      <div className="flex justify-center">
        <input
          type="text"
          name="company_website"
          id="company_website"
          className="focus:ring-indigo-500 focus:border-indigo-500 block md:h-20 w-2/3  sm:text-sm border-gray-300 rounded-l-md"
          placeholder="Search Anything"
        />
        <button className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span>Go</span>
        </button>
      </div>
      <div className="flex flex-col md:flex-row justify-evenly m-20">
        <ExploreTile />
        <ExploreTile />
        <ExploreTile />
        <ExploreTile />
      </div>
    </div>
  )
}

export default Kb

const ExploreTile = () => {
  return (
    <div className="bg-white object-contain shadow-md rounded-2xl m-5">
      <div className="p-10 flex flex-col items-center text-center">
        <p className="text-xl font-medium">Video</p>
        <VideoCameraIcon
          className="h-2/3 w-2/3 text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>
  )
}
