import { ReactNode, useState } from "react"
import TabBar from "../TabBar/TabBar"

export interface Tab {
  title: string
  body: ReactNode
}

interface TabViewProps {
  tabs: Array<Tab>
  defaultTabIdx?: number
}
const TabView: React.FC<TabViewProps> = ({
  tabs,
  defaultTabIdx
}: TabViewProps) => {
  const [activeTabIdx, setActiveTabIdx] = useState(defaultTabIdx ?? 0)
  const tabTitles = tabs.map(tab => tab.title)
  return (
    <div>
      <TabBar
        tabs={tabTitles}
        activeTab={activeTabIdx}
        setActiveTab={setActiveTabIdx}
      />
      <div className="pt-5">
        {tabs.map((tab, idx) => {
          return idx === activeTabIdx && tab.body
        })}
      </div>
    </div>
  )
}

export default TabView
