import React from "react"
import MobileMenu from "../mobile/MobileMenu"
import { sidebarNavigation } from "../../constants"
import SideBar from "../SideBar"
import TopBar from "../TopBar"
import AppPageParent from "./AppPageParent"

interface Props {
  mobileMenuOpen: boolean
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  children: React.ReactNode
}
const AppLayout: React.FC<Props> = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  title,
  children
}: Props) => {
  return (
    <div className="h-screen bg-gray-50 flex overflow-hidden">
      <SideBar items={sidebarNavigation} />
      <MobileMenu
        items={sidebarNavigation}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <div className="flex-1 flex flex-col">
        <TopBar setMobileMenuOpen={setMobileMenuOpen} title={title} />
        <AppPageParent>{children}</AppPageParent>
      </div>
    </div>
  )
}
export default AppLayout
