/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React from "react"
import { useTable, Column } from "react-table"
import { StudentData } from "../../pages/Attendance"
import { AttendanceModeProps } from "../RollCallMode"
import Toggle from "../Toggle"

const UpdateAttendance: React.FC<AttendanceModeProps> = ({
  data: globalData,
  setData
}: AttendanceModeProps) => {
  const data = React.useMemo(() => globalData, [globalData])
  const setter = (idx: number) => {
    return (value: StudentData["status"]) => {
      const tempData = [...data]
      tempData[idx].status = value
      setData(tempData)
    }
  }
  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Student Name",
          accessor: "name" // accessor is the "key" in the data
        },
        {
          Header: "Current Status",
          accessor: "status",
          Cell: ({ row }) => (
            <Toggle
              enabled={row.values.status}
              setEnabled={() => setter(row.index)(!row.values.status)}
            />
          )
        }
      ] as Column<StudentData>[],
    []
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<StudentData>({ columns, data })
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateAttendance
