import React, { ReactChild } from "react"
import LogoWhite from "../../assets/images/logo-white.png"
import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import MobileSideBarTile from "./MobileSideBarTile"
import { Tile } from "../SideBarTile/SideBarTile"

interface Props {
  items: Array<Tile>
  mobileMenuOpen: boolean
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const MobileMenu: React.FC<Props> = ({
  items,
  mobileMenuOpen,
  setMobileMenuOpen
}: Props) => {
  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="md:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-40 flex">
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />

          <div className="relative max-w-xs w-full bg-primary-700 pt-5 pb-4 flex-1 flex flex-col">
            <OffsetRight>
              <CloseButton setMobileMenuOpen={setMobileMenuOpen} />
            </OffsetRight>

            <Logo />
            <TilesList items={items} />
          </div>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default MobileMenu

const Logo = () => {
  return (
    <div className="flex-shrink-0 px-4 flex items-center">
      <img className="h-8 w-auto" src={LogoWhite} alt="Workflow" />
    </div>
  )
}

interface TilesListProps {
  items: Array<Tile>
}
const TilesList = ({ items }: TilesListProps) => {
  return (
    <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
      <nav className="h-full flex flex-col">
        <div className="space-y-1">
          {items.map((item, idx) => (
            <MobileSideBarTile item={item} key={idx} />
          ))}
        </div>
      </nav>
    </div>
  )
}

interface CloseButtonProps {
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const CloseButton = ({ setMobileMenuOpen }: CloseButtonProps) => {
  return (
    <button
      type="button"
      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
      onClick={() => setMobileMenuOpen(false)}
    >
      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
      <span className="sr-only">Close sidebar</span>
    </button>
  )
}
interface OffsetRightProps {
  children: ReactChild
}

const OffsetRight: React.FC<OffsetRightProps> = ({
  children
}: OffsetRightProps) => {
  return <div className="absolute top-1 right-0 -mr-14 p-1">{children}</div>
}
